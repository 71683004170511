// src/api/myFunctions.js
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase-config";

export async function getDashboardData() {
  const getDashboardData = httpsCallable(functions, "getDashboardData");
  const response = await getDashboardData();
  // response.data => { orgs: [...] }
  return response.data.orgs;
}

export async function updateAutoReview(repoId, autoReviewEnabled) {
  const fn = httpsCallable(functions, "updateRepoAutoReview");
  const response = await fn({ repoId, autoReviewEnabled });
  // response.data => { message: "Auto review updated successfully." }
  return response.data;
}

export async function updateAutoAssignSeats(orgId, autoAssignSeats) {
  const fn = httpsCallable(functions, "updateAutoAssignSeats");
  const response = await fn({ orgId, autoAssignSeats });
  // response.data => { org: {...}, seats: [...] }
  return response.data;
}

export async function createCheckoutSession(
  orgId,
  amount,
  successUrl,
  cancelUrl
) {
  const fn = httpsCallable(functions, "createCheckoutSession");
  const response = await fn({ orgId, amount, successUrl, cancelUrl });
  return response.data;
}

export async function createPortalSession(orgId, returnUrl) {
  const fn = httpsCallable(functions, "createPortalSession");
  const response = await fn({ orgId, returnUrl });
  return response.data;
}

export async function batchSeatsUpdate(orgId, additions, removals) {
  const fn = httpsCallable(functions, "batchSeatsUpdate");
  const response = await fn({ orgId, additions, removals });
  return response.data;
}

export async function updateMemory(repoId, useMemory) {
  const fn = httpsCallable(functions, "updateRepoMemory");
  const response = await fn({ repoId, useMemory });
  return response.data;
}
