import "./Loading.scss";

const FpErrorMessage = ({ message }) => {
  return (
    <div className="fccc loading">
      <p style={{ color: "red" }}>⚠️ {message}</p>
      <a
        href="https://github.com/apps/devloai/installations/select_target"
        target="_blank"
        rel="noopener noreferrer"
        className="frcc cta-btn"
      >
        {" "}
        Connect Github organization
      </a>
      <p className="subnote">
        Note that you can only add and view organizations that you are an admin
        of
      </p>
    </div>
  );
};

export default FpErrorMessage;
