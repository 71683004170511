import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { getDashboardData } from "../api/dashboardFunctions";

const OrgContext = createContext();

export function OrgProvider({ children }) {
  const { firebaseUser } = useAuth(); // from your AuthContext
  const [orgs, setOrgs] = useState([]);
  const [currentOrg, setCurrentOrg] = useState(null);
  const [loadingOrgs, setLoadingOrgs] = useState(true);
  const [orgError, setOrgError] = useState("");

  useEffect(() => {
    // If user is not logged in, reset org data
    if (!firebaseUser) {
      setOrgs([]);
      setCurrentOrg(null);
      setLoadingOrgs(false);
      // Also remove any leftover 'selectedOrgId' in local storage
      localStorage.removeItem("selectedOrgId");
      return;
    }

    // If user is logged in, fetch orgs
    async function loadOrgs() {
      setLoadingOrgs(true);
      setOrgError("");
      try {
        const orgList = await getDashboardData(); // calls your Cloud Function
        setOrgs(orgList);
        // See if there's a stored org ID from localStorage
        const storedOrgId = localStorage.getItem("selectedOrgId");
        if (storedOrgId) {
          // If it matches an org in the list, set that as current
          const foundOrg = orgList.find(
            (o) => o.id.toString() === storedOrgId.toString()
          );
          if (foundOrg) {
            setCurrentOrg(foundOrg);
          } else if (orgList.length > 0) {
            // fallback to first org if stored one no longer valid
            setCurrentOrg(orgList[0]);
            localStorage.setItem("selectedOrgId", orgList[0].id);
          }
        } else if (orgList.length > 0) {
          // Otherwise fallback to the first org
          setCurrentOrg(orgList[0]);
          localStorage.setItem("selectedOrgId", orgList[0].id);
        }
      } catch (err) {
        console.error("Error loading orgs:", err);
        setOrgError("Failed to load orgs");
      } finally {
        setLoadingOrgs(false);
      }
    }

    loadOrgs();
  }, [firebaseUser]);

  const selectOrgId = (orgId) => {
    // Find the org object in the orgs array
    const foundOrg = orgs.find((o) => o.id.toString() === orgId.toString());

    if (foundOrg) {
      setCurrentOrg(foundOrg);
      localStorage.setItem("selectedOrgId", orgId); // Store the chosen ID
    } else {
      // Optionally handle the case where the ID doesn't match any org
      console.warn(`No organization found with id: ${orgId}`);
      setCurrentOrg(null);
      localStorage.removeItem("selectedOrgId");
    }
  };

  return (
    <OrgContext.Provider
      value={{
        orgs,
        currentOrg,
        setCurrentOrg,
        selectOrgId,
        loadingOrgs,
        orgError,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
}

export function useOrgContext() {
  return useContext(OrgContext);
}
