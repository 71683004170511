// RepositoryDetail.js
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import "../components/Dashboard.scss";
import LogoNavbar from "../components/utils/LogoNavbar";
import Loading from "../components/utils/Loading";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  updateAutoReview,
  updateMemory,
} from "../components/api/dashboardFunctions";
import FpErrorMessage from "../components/utils/FpErrorMessage";
import { useOrgContext } from "../components/utils/OrgContext";

const RepositoryDetail = () => {
  const navigate = useNavigate();
  const { repoId } = useParams();
  const { currentOrg, loadingOrgs } = useOrgContext();
  const [repository, setRepository] = useState(null);

  const [initialAutoReviewEnabled, setInitialAutoReviewEnabled] =
    useState(false);
  const [newAutoReviewEnabled, setNewAutoReviewEnabled] = useState(false);
  const [initialUseMemory, setInitialUseMemory] = useState(true);
  const [newUseMemory, setNewUseMemory] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");

  useEffect(() => {
    if (currentOrg) {
      const foundRepo = currentOrg.repos.find(
        (r) => r.id.toString() === repoId
      );
      if (foundRepo) {
        setRepository(foundRepo);
        setInitialAutoReviewEnabled(foundRepo.autoReviewEnabled);
        setNewAutoReviewEnabled(foundRepo.autoReviewEnabled);
        setInitialUseMemory(foundRepo.useMemory ?? true);
        setNewUseMemory(foundRepo.useMemory ?? true);
      } else {
        setRepository(null);
        navigate("/repos");
      }
    }
  }, [currentOrg, repoId, navigate]);

  const saveSettings = async () => {
    setSaving(true);
    setSaveMessage("");
    try {
      if (newAutoReviewEnabled !== initialAutoReviewEnabled) {
        await updateAutoReview(repoId, newAutoReviewEnabled);
        setInitialAutoReviewEnabled(newAutoReviewEnabled);
      }
      if (newUseMemory !== initialUseMemory) {
        await updateMemory(repoId, newUseMemory);
        setInitialUseMemory(newUseMemory);
      }
      setIsDirty(false);
      setSaveMessage("Settings saved successfully.");
    } catch (error) {
      console.error("Error updating settings:", error);
      alert("Failed to save settings. Please try again.");
    }
    setSaving(false);
  };

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="repos" />
      {loadingOrgs ? (
        <Loading />
      ) : currentOrg ? (
        <div className="main-outer">
          <div className="f-col aifs main repo-deets">
            <LogoNavbar />
            {repository ? (
              <>
                <div className="frcc route">
                  <Link to="/repos">Repositories</Link>
                  <i className="material-icons icn">navigate_next</i>{" "}
                  {repository.full_name}
                </div>
                <div className="f-row aic jcsb header">
                  <div className="frcc lefty">
                    <a
                      href={repository.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="frcc title"
                    >
                      <h1 className="f-row aifs">
                        {repository.full_name}{" "}
                        <i className="material-icons open-icn">open_in_new</i>
                      </h1>
                    </a>
                    <div
                      className={
                        `f-row aic status-badge ` +
                        (repository.active ? "active" : "inactive")
                      }
                    >
                      {repository.active && (
                        <span className="pulsating-circle"></span>
                      )}
                      {repository.active ? "Active" : "Inactive"}
                    </div>
                  </div>
                  <a
                    href={`https://github.com/settings/installations/${repository.installationId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn"
                  >
                    Modify Installation
                  </a>
                </div>
                <div className="f-row directive">
                  {repository.active ? (
                    <p>
                      devlo is active on pull requests for this repository.{" "}
                      <br />
                      You can ask questions or request code changes by starting
                      your comments with <span className="code">@devloai</span>
                    </p>
                  ) : (
                    <p>
                      devlo will not perform any actions on this pull request
                    </p>
                  )}
                </div>
                <div className="toggle-section">
                  <h2>Settings</h2>
                  <FormGroup>
                    <FormControlLabel
                      label={
                        <div>
                          <span>Automatically review new pull requests</span>
                          <div className="toggle-subtext">
                            When enabled, devlo will review new pull requests
                            automatically.
                          </div>
                        </div>
                      }
                      control={
                        <Switch
                          checked={newAutoReviewEnabled}
                          onChange={(e) => {
                            const newValue = e.target.checked;
                            setNewAutoReviewEnabled(newValue);
                            setIsDirty(
                              newValue !== initialAutoReviewEnabled ||
                                newUseMemory !== initialUseMemory
                            );
                          }}
                          name="auto-review-toggle"
                          color="primary"
                        />
                      }
                    />
                    <FormControlLabel
                      label={
                        <div className="label-container">
                          <span className="main-label">Memory</span>
                          <div className="toggle-subtext">
                            Let devlo learn and adapt to your codebase as it
                            works, making its suggestions more accurate and its
                            assistance more valuable over time.
                          </div>
                        </div>
                      }
                      control={
                        <Switch
                          checked={newUseMemory}
                          onChange={(e) => {
                            const newValue = e.target.checked;
                            setNewUseMemory(newValue);
                            setIsDirty(
                              newValue !== initialUseMemory ||
                                newAutoReviewEnabled !==
                                  initialAutoReviewEnabled
                            );
                          }}
                          name="memory-toggle"
                          color="primary"
                        />
                      }
                    />
                  </FormGroup>
                  <button
                    className={`save-btn ${isDirty ? "active" : "disabled"}`}
                    disabled={!isDirty || saving}
                    onClick={saveSettings}
                  >
                    {saving ? "Saving..." : "Save"}
                  </button>
                  {saveMessage && (
                    <div className="success-message">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31"
                        height="27"
                        viewBox="0 0 31 27"
                        fill="none"
                      >
                        <path
                          d="M21.0687 9.00907C21.3329 8.79167 21.6844 8.67148 22.049 8.67386C22.4137 8.67625 22.763 8.80102 23.0233 9.02186C23.2836 9.24269 23.4345 9.54232 23.4441 9.8575C23.4538 10.1727 23.3214 10.4788 23.075 10.7112L15.5937 18.8004C15.4651 18.9202 15.3098 19.0163 15.1372 19.083C14.9646 19.1498 14.7782 19.1857 14.5892 19.1887C14.4002 19.1918 14.2124 19.1618 14.037 19.1006C13.8617 19.0395 13.7024 18.9484 13.5687 18.8328L8.60749 14.5434C8.46933 14.4321 8.35851 14.2979 8.28165 14.1488C8.20479 13.9996 8.16346 13.8386 8.16013 13.6754C8.1568 13.5121 8.19153 13.35 8.26226 13.1986C8.33299 13.0472 8.43826 12.9097 8.57179 12.7942C8.70533 12.6788 8.86439 12.5878 9.03949 12.5266C9.2146 12.4655 9.40215 12.4354 9.59097 12.4383C9.77979 12.4412 9.966 12.4769 10.1385 12.5434C10.311 12.6098 10.4662 12.7056 10.595 12.8251L14.5212 16.218L21.0331 9.04474L21.0687 9.00907Z"
                          fill="#0EBB44"
                        />
                      </svg>
                      {saveMessage}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <FpErrorMessage message={"Repository not found"} />
            )}
          </div>
        </div>
      ) : (
        <FpErrorMessage message={"Organization not found"} />
      )}
    </div>
  );
};

export default RepositoryDetail;
