import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import React, { useEffect, useRef, useState } from "react";
import "./Home.scss";
import githubLogo from "../assets/github-mark-white.png";
import jsLogo from "../assets/js-logo.png";
import tsLogo from "../assets/ts-logo.png";
import pyLogo from "../assets/py-logo.png";
import testPng from "../assets/test.png";
import gitPrLogo from "../assets/git-pr.png";
import reviewImg from "../assets/review.png";
import demoVideo from "../assets/demo.mp4";
import demoPoster from "../assets/demo-poster.png";
import bgVideo from "../assets/bg-video.mp4";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import {
  trackVideoEvent,
  trackCTAClick,
  trackSectionView,
  trackPricingInteraction,
} from "../components/utils/analytics";
import Tooltip from "@mui/material/Tooltip";

function Home() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash && document.getElementById(hash.substr(1))) {
      const sectionId = hash.substr(1);
      document.getElementById(sectionId).scrollIntoView();
      trackSectionView(sectionId);
    } else {
      trackSectionView("home");
    }

    // Set up intersection observer for section tracking
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            trackSectionView(entry.target.id || "unnamed-section");
          }
        });
      },
      { threshold: 0.5 }
    );

    // Observe all sections
    document.querySelectorAll(".section").forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const [hasPlayed, setHasPlayed] = useState(false);

  const handlePlay = () => {
    if (!hasPlayed) {
      if (videoRef.current) {
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen().catch((err) => {
            console.error(
              `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
            );
          });
        }
        setHasPlayed(true);
        trackVideoEvent("first_play", "demo_video");
      }
    }
  };

  const togglePlay = (e) => {
    e.preventDefault();
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setControlsVisible(true); // Show controls once video plays
        setIsPlaying(true);
        trackVideoEvent("play", "demo_video");
      } else {
        video.pause();
        setIsPlaying(false);
        trackVideoEvent("pause", "demo_video");
      }
    }
  };

  const check = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="27"
      viewBox="0 0 31 27"
      fill="none"
    >
      <path
        d="M21.0687 9.00907C21.3329 8.79167 21.6844 8.67148 22.049 8.67386C22.4137 8.67625 22.763 8.80102 23.0233 9.02186C23.2836 9.24269 23.4345 9.54232 23.4441 9.8575C23.4538 10.1727 23.3214 10.4788 23.075 10.7112L15.5937 18.8004C15.4651 18.9202 15.3098 19.0163 15.1372 19.083C14.9646 19.1498 14.7782 19.1857 14.5892 19.1887C14.4002 19.1918 14.2124 19.1618 14.037 19.1006C13.8617 19.0395 13.7024 18.9484 13.5687 18.8328L8.60749 14.5434C8.46933 14.4321 8.35851 14.2979 8.28165 14.1488C8.20479 13.9996 8.16346 13.8386 8.16013 13.6754C8.1568 13.5121 8.19153 13.35 8.26226 13.1986C8.33299 13.0472 8.43826 12.9097 8.57179 12.7942C8.70533 12.6788 8.86439 12.5878 9.03949 12.5266C9.2146 12.4655 9.40215 12.4354 9.59097 12.4383C9.77979 12.4412 9.966 12.4769 10.1385 12.5434C10.311 12.6098 10.4662 12.7056 10.595 12.8251L14.5212 16.218L21.0331 9.04474L21.0687 9.00907Z"
        fill="#0EBB44"
      />
    </svg>
  );

  return (
    <>
      <Navbar />
      <div className="content fcol home">
        <div className="section">
          <div className="lim-width">
            <div className="frcc hero">
              <div className="f-col hero-left">
                <div>
                  <h4 className="main-subheading pre"> Say hello to</h4>
                  <h1 className="pb-grd main-heading">
                    Your AI-developer teammate
                  </h1>
                </div>
                <h4 className="main-subheading">
                  Cut Development Time by over 60%
                </h4>
                <p className="subtext">
                  <br />
                  devlo boosts your team's productivity by handling development
                  tasks, freeing you to focus on innovation and ship products
                  faster
                  <br />
                </p>
                <div className="fccc cta-container">
                  <Link
                    to="/login"
                    onClick={() =>
                      trackCTAClick("start_free_trial", "hero_section")
                    }
                  >
                    <button className="cta-btn cta-btn-home">
                      Start free trial
                    </button>
                  </Link>
                  <p className="p-light">No credit card required</p>
                </div>
              </div>
              <div>
                <div className="video-container" onClick={togglePlay}>
                  <video
                    ref={videoRef}
                    poster={demoPoster}
                    width="100%"
                    height="auto"
                    playsInline
                    preload="metadata"
                    src={demoVideo}
                    onPlay={handlePlay}
                    onEnded={() => trackVideoEvent("complete", "demo_video")}
                    type="video/mp4"
                    controls={controlsVisible} // Controls visibility managed by state
                    // style={{
                    //   outline: controlsVisible ? "none" : "0.1px solid grey",
                    // }} // Optional: add outline when controls are hidden
                  >
                    Your browser does not support the video tag.
                  </video>
                  {!isPlaying && (
                    <div className="play-button">
                      <i className="material-icons open-icn">play_arrow</i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section bg-section">
          <video
            className="bg-video"
            autoPlay
            loop
            muted
            playsInline
            src={bgVideo}
            type="video/mp4"
          />
          <div className="bg-overlay">
            <h2 className="overlay-text">
              Built for teams that like to move fast
            </h2>
          </div>
        </div>
        <div className="fccc section lim-width issue-section" id="features">
          <div className="fccc heading">
            <div>
              <h2>
                Solve tickets <span className="pb-grd"> in minutes</span>
              </h2>
            </div>
            <p>
              Clear your issue backlog with the help of <br />a powerful AI
              developer
            </p>
          </div>

          <div className="panels">
            <div className="f-col panel">
              <div className="frcc tkt-pill bug">
                <i className="material-icons">bug_report</i>
                <h4 className="bold">Bug</h4>
              </div>
              <p>
                Let devlo squash your bugs automatically, reducing downtime &
                improving code quality
              </p>
            </div>
            <div className="f-col panel">
              <div className="frcc tkt-pill ft">
                <i className="material-icons">campaign</i>
                <h4 className="bold">Feature</h4>
              </div>
              <p>
                Accelerate new feature development and deliver value to your
                users at thought speed
              </p>
            </div>
            <div className="f-col panel">
              <div className="frcc tkt-pill en">
                <i className="material-icons">blur_on</i>
                <h4 className="bold">Enhancement</h4>
              </div>
              <p>
                From code refactoring to performance optimization, devlo has
                your back
              </p>
            </div>
            <div className="f-col panel">
              <div className="frcc tkt-pill qa">
                <i className="material-icons">forum</i>
                <h4 className="bold">Question</h4>
              </div>
              <p>
                Ask questions related to your codebase and get instant, expert
                answers
              </p>
            </div>
            <div className="frcc panel full-width feat-sec">
              {/* <div className="frcc"> */}
              <div className="fccc">
                <img src={gitPrLogo} alt="github pull request logo"></img>
                <h4>Validated code changes</h4>
              </div>
              <div className="f-col feat-dsc">
                <p>devlo generates accurate pull requests</p>
                <ul>
                  <li>
                    <i className="material-icons">chevron_right</i>Understands
                    your codebase
                  </li>
                  <li>
                    <i className="material-icons">chevron_right</i>
                    <span>Sets up a real development environment</span>
                  </li>
                  <li>
                    <i className="material-icons">chevron_right</i>
                    <span>validates changes through tests</span>
                  </li>
                </ul>
              </div>
              {/* </div> */}
            </div>
            <div className="fccc cta-panel">
              <p className="cta-text">Ready to power through your backlog?</p>
              <Link
                to="/login"
                onClick={() =>
                  trackCTAClick("get_started_now", "features_section")
                }
              >
                <button className="cta-btn cta-btn-home">
                  Get started now
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="fccc section lim-width pr-section">
          <div className="fccc heading">
            <div>
              <h2>
                <span className="pb-grd">Accelerate </span>Pull Requests
              </h2>
            </div>
            <p>
              Get your code across the finish line <br />
              Ship code changes faster, smarter, and safer.
            </p>
          </div>

          <div className="panels">
            <div className="panel cmn-panel code-review-panel">
              <div className="fccc text-section">
                <h3 className="bold">Code Reviews</h3>
                <p>
                  Get a detailed code review in under a minute. <br />
                  <br />
                  Catch bugs, security risks, performance issues, and more
                  before going live.
                </p>
              </div>
              <div className="image-section">
                <img
                  src={reviewImg}
                  alt="Sample code review"
                  className="code-review-img"
                />
              </div>
            </div>

            <div className="f-col panel cmn-panel testing-panel">
              <h3 className="bold hdg">
                <img src={testPng} alt="test" />
                <br />
                Automated Testing
              </h3>
              <p>
                Adding unit tests is tedious. We get it. <br />
                devlo adds, runs & fixes unit tests, so you don't have to.
              </p>
            </div>
            <div className="f-col panel cmn-panel follow-up-panel">
              <h3 className="bold hdg">
                <i className="material-icons">published_with_changes</i>
                <br />
                Follow-up Tasks
              </h3>
              <p>
                Let devlo handle any follow-up tasks on your pull request like
                code updates, refactors etc.
              </p>
            </div>
          </div>
        </div>
        <div className="fccc section dot-bg">
          <div className="fccc section lim-width interactive-section">
            <div className="fccc heading">
              <h4>
                devlo addresses comments on GitHub issues and pull requests
              </h4>
              <p>
                All you have to do is <span className="bold">ask.</span>
              </p>
            </div>
            <div className="prompter">
              <div className="f-row window">
                <span className="bold">@devloai&nbsp;</span>
                <Typewriter
                  options={{
                    strings: [
                      "resolve this bug",
                      "implement this feature request",
                      "review this pull request",
                      "generate unit tests for this file",
                      "make this function more efficient",
                      "summarize the changes made in this PR",
                      "add error handling and logging to this function",
                      "fix styling issues and add function comments",
                    ],
                    autoStart: true,
                    loop: true,
                    pauseFor: 1200,
                    deleteSpeed: 10,
                    delay: 25,
                  }}
                />
              </div>
            </div>
          </div>
          <Link to="/login">
            <button className="cta-btn cta-btn-home">Get started now</button>
          </Link>
        </div>
        <div className="fccc section lim-width stats-section">
          <h2>
            <span className="pb-grd">Zero </span> onboarding time
          </h2>
          <p>Supercharge your development velocity from Day 1</p>
          <div className="stats fw">
            <div className="fccc shadow-box">
              <div className="fccc ">
                <div className="stat-num">100+</div>
                <div className="stat-desc">tickets resolved daily</div>
              </div>
            </div>
            <div className="fccc shadow-box">
              <div className="fccc ">
                <div className="stat-num">1000+</div>
                <div className="stat-desc">hours of dev time saved weekly</div>
              </div>
            </div>
            <div className="fccc shadow-box">
              <div className="fccc ">
                <div className="stat-num">1,000,000+</div>
                <div className="stat-desc">lines of code submitted</div>
              </div>
            </div>
          </div>
        </div>
        <div className="frcc lim-width section intgr-section">
          <div className="f-col lefty">
            <h3>
              <span className="pb-grd">Accelerate </span>your development today
            </h3>
            <p>
              devlo integrates with your Github repositories in a few clicks.
            </p>
            <Link
              to="/login"
              onClick={() =>
                trackCTAClick("integrate_github", "integration_section")
              }
            >
              <button className="frcc cta-btn cta-btn-home mod-1">
                <img src={githubLogo} alt="github logo" />
                Integrate with Github now
              </button>
            </Link>
          </div>
          <div className="fccc f-wrap boxes">
            <div className="fccc box  panel-bg">
              <h4>All major languages supported</h4>
              <div className="frcc logos">
                <img src={jsLogo} alt="javascript"></img>
                <img src={tsLogo} alt="typescript"></img>
                <img src={pyLogo} alt="python"></img>
              </div>
              <p>and more</p>
            </div>
          </div>
        </div>
        <div className="fccc lim-width sec-section">
          <h3>
            Built with <span className="pb-grd">Security & Privacy</span> in
            mind
          </h3>
          <div className="frcc boxes">
            <div className="fccc box">
              <div className="graphic">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="76"
                  height="76"
                  viewBox="0 0 76 76"
                  fill="none"
                >
                  <g clipPath="url(#clip0_293_13)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M28.1363 2.71423C29.9891 2.67007 31.8015 3.25926 33.2741 4.38446C34.7467 5.50966 35.7914 7.10354 36.2357 8.9028L37.4029 13.5714H67.8571C70.0168 13.5714 72.0879 14.4293 73.615 15.9564C75.1421 17.4834 76 19.5546 76 21.7142V65.1428C76 67.3024 75.1421 69.3736 73.615 70.9007C72.0879 72.4277 70.0168 73.2857 67.8571 73.2857H8.14286C5.98324 73.2857 3.91207 72.4277 2.38499 70.9007C0.857906 69.3736 0 67.3024 0 65.1428L0 10.8571C0 8.69746 0.857906 6.6263 2.38499 5.09921C3.91207 3.57213 5.98324 2.71423 8.14286 2.71423H28.1363ZM24.4286 39.3571C23.3488 39.3571 22.3132 39.786 21.5496 40.5496C20.7861 41.3131 20.3571 42.3487 20.3571 43.4285C20.3571 44.5083 20.7861 45.5439 21.5496 46.3074C22.3132 47.071 23.3488 47.4999 24.4286 47.4999H51.5714C52.6512 47.4999 53.6868 47.071 54.4504 46.3074C55.2139 45.5439 55.6429 44.5083 55.6429 43.4285C55.6429 42.3487 55.2139 41.3131 54.4504 40.5496C53.6868 39.786 52.6512 39.3571 51.5714 39.3571H24.4286Z"
                      fill="#00ABFF"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_293_13">
                      <rect width="76" height="76" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p>
                Only relevant code is read. All code is deleted upon completion
              </p>
            </div>
            <div className="fccc box">
              <div className="graphic">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="71"
                  height="71"
                  viewBox="0 0 71 71"
                  fill="none"
                >
                  <path
                    d="M46.0021 37.497L33.5031 24.998L47.925 10.5761C48.5167 9.98447 49.1705 9.55354 49.8864 9.28335C50.6023 9.01315 51.3537 8.87707 52.1406 8.8751C52.9275 8.87313 53.6918 9.02104 54.4333 9.31885C55.1749 9.61665 55.8159 10.0604 56.3562 10.6501L60.424 14.7918C61.0156 15.3341 61.4465 15.9751 61.7167 16.7147C61.9869 17.4543 62.123 18.1938 62.125 18.9334C62.125 19.7223 61.9889 20.4747 61.7167 21.1906C61.4446 21.9066 61.0136 22.5594 60.424 23.1491L46.0021 37.497ZM58.575 66.8584L37.6448 46.0022L21.4479 62.1251H8.875V49.6261L25.0719 33.4293L4.14166 12.4251L8.35729 8.20947L62.7906 62.6428L58.575 66.8584ZM52.0667 23.0751L56.2083 18.9334L52.0667 14.7918L47.925 18.9334L52.0667 23.0751Z"
                    fill="#00ABFF"
                  />
                </svg>
              </div>
              <p>Your data is never used to train models</p>
            </div>
            <div className="fccc box">
              <div className="graphic">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="71"
                  height="71"
                  viewBox="0 0 71 71"
                  fill="none"
                >
                  <path
                    d="M17.75 65.0833C16.1229 65.0833 14.7305 64.5044 13.5728 63.3467C12.4151 62.189 11.8353 60.7956 11.8333 59.1666V29.5833C11.8333 27.9562 12.4132 26.5638 13.5728 25.4061C14.7325 24.2484 16.1249 23.6686 17.75 23.6666H20.7083V17.7499C20.7083 13.6576 22.151 10.1697 25.0364 7.2863C27.9217 4.40291 31.4096 2.96023 35.5 2.95825C39.5904 2.95628 43.0793 4.39896 45.9666 7.2863C48.8539 10.1736 50.2956 13.6615 50.2917 17.7499V23.6666H53.25C54.8771 23.6666 56.2705 24.2464 57.4301 25.4061C58.5898 26.5658 59.1687 27.9581 59.1667 29.5833V59.1666C59.1667 60.7937 58.5878 62.187 57.4301 63.3467C56.2724 64.5064 54.8791 65.0852 53.25 65.0833H17.75ZM35.5 50.2916C37.1271 50.2916 38.5205 49.7127 39.6801 48.555C40.8398 47.3974 41.4187 46.004 41.4167 44.3749C41.4147 42.7459 40.8359 41.3535 39.6801 40.1978C38.5244 39.042 37.131 38.4622 35.5 38.4583C33.869 38.4543 32.4766 39.0341 31.3228 40.1978C30.1691 41.3614 29.5893 42.7538 29.5833 44.3749C29.5774 45.9961 30.1573 47.3895 31.3228 48.555C32.4884 49.7206 33.8808 50.2995 35.5 50.2916ZM26.625 23.6666H44.375V17.7499C44.375 15.2846 43.5122 13.1892 41.7865 11.4635C40.0608 9.73777 37.9653 8.87492 35.5 8.87492C33.0347 8.87492 30.9392 9.73777 29.2136 11.4635C27.4879 13.1892 26.625 15.2846 26.625 17.7499V23.6666Z"
                    fill="#00ABFF"
                  />
                </svg>
              </div>
              <p>Enterprise-grade security by default</p>
            </div>
          </div>
        </div>

        <div id="pricing" className="fccc section lim-width pricing">
          <h2>Pricing</h2>
          <div className="frcc boxes">
            <div className="fccc shadow-box">
              <h3 className="home-icn">Pro</h3>
              <div className="price">
                $39<span className="subscript">/month</span>
              </div>
              <div className="divider"></div>
              <div className="f-col features">
                <div className="f-row aic feat">
                  {check}
                  <Tooltip
                    title={
                      <span>
                        Credits are the units of work devlo performs. Learn more
                        about how credits work in our{" "}
                        <a
                          href="/faq#what-are-devlo-credits"
                          style={{ color: "#fff", textDecoration: "underline" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          FAQ
                        </a>
                        .
                      </span>
                    }
                    arrow
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "rgba(0, 0, 0, 0.87)",
                          fontSize: "0.875rem",
                          padding: "10px",
                        },
                      },
                    }}
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                  >
                    <p className="crd">4500 credits</p>
                  </Tooltip>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Ticket resolution</p>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Pull requests</p>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Basic support</p>
                </div>
              </div>
              <Link
                to="/login"
                onClick={() => trackPricingInteraction("free", "get_started")}
              >
                <button className="cta-btn">Start free trial</button>
              </Link>
            </div>
            <div className="fccc shadow-box most-popular">
              <div className="popular-banner">Most Popular</div>
              <h3 className="home-icn">Startup</h3>
              <div className="price">
                $199<span className="subscript">/month</span>
              </div>
              <div className="divider"></div>
              <div className="f-col features">
                <div className="f-row aic feat">
                  {check}
                  <Tooltip
                    title={
                      <span>
                        Credits are the units of work devlo performs. Learn more
                        about how credits work in our{" "}
                        <a
                          href="/faq#what-are-devlo-credits"
                          style={{ color: "#fff", textDecoration: "underline" }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          FAQ
                        </a>
                        .
                      </span>
                    }
                    arrow
                    placement="top"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "rgba(0, 0, 0, 0.87)",
                          fontSize: "0.875rem",
                          padding: "10px",
                        },
                      },
                    }}
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                  >
                    <p className="crd">25000 credits</p>
                  </Tooltip>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Team management</p>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Priority support</p>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Advanced Reporting</p>
                </div>
              </div>
              <Link
                to="/login"
                onClick={() => trackPricingInteraction("free", "get_started")}
              >
                <button className="cta-btn">Get started</button>
              </Link>
            </div>
            <div className="fccc shadow-box">
              <h3 className="home-icn">Enterprise</h3>
              <div className="price">Talk to us</div>
              <div className="divider"></div>
              <div className="f-col features">
                <div className="f-row aic feat">
                  {check}
                  <p>VPC Integration</p>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Self hosted LLMs</p>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Custom solutions</p>
                </div>
                <div className="f-row aic feat">
                  {check}
                  <p>Dedicated support</p>
                </div>
              </div>
              <Link
                to="/contact-us"
                onClick={() =>
                  trackPricingInteraction("enterprise", "contact_us")
                }
              >
                <button className="cta-btn contact-btn">Contact us</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
