import React from "react";
import "./ConfirmationModal.scss";

const ConfirmationModal = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  pendingAdditions,
  pendingRemovals,
  unifiedSeats,
}) => {
  if (!isOpen) return null;

  // For removals, find the user details from unifiedSeats
  const removalDetails = pendingRemovals.map((seatId) => {
    const seat = unifiedSeats.find((s) => s.userGithubId.toString() === seatId);
    return seat || { username: seatId }; // Fallback if not found
  });

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        {message && <p>{message}</p>}

        {pendingAdditions && pendingAdditions.length > 0 && (
          <div className="changes-section">
            <h3>Users to be Added:</h3>
            <ul>
              {pendingAdditions.map(({ username, userDetails }) => (
                <li key={username}>
                  <img
                    src={userDetails.avatar_url}
                    alt={username}
                    className="user-avatar"
                  />
                  <span className="user-name">{username}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {removalDetails && removalDetails.length > 0 && (
          <div className="changes-section">
            <h3>Seats to be Removed:</h3>
            <ul>
              {removalDetails.map((seat, idx) => (
                <li key={pendingRemovals[idx]}>
                  {seat.avatarUrl && (
                    <img
                      src={seat.avatarUrl}
                      alt={seat.username}
                      className="user-avatar"
                    />
                  )}
                  <span className="user-name">
                    {seat.username || pendingRemovals[idx]}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="modal-actions">
          <button onClick={onCancel} className="secondary">
            Cancel
          </button>
          <button onClick={onConfirm} className="primary">
            Confirm Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
