import "./HowItWorks.scss";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const faqData = [
  {
    question: "How does devlo work?",
    answer: (
      <p>
        devlo integrates with your Github repository and responds to comments on
        pull requests that start with &nbsp;
        <span className="bold">@devloai</span>.<br />
        <br />
        devlo has been built to perform like a human software engineer. Behind
        the scenes, devlo decides the steps to take to address the task,
        executes it, and then replies to the comment with the result. More
        capabilities will be added over time.{" "}
        <Link to="/contact-us">Contact Us</Link> if you would like to see devlo
        perform a particular task.
      </p>
    ),
  },
  {
    question: "What are devlo credits?",
    answer: (
      <p>
        devlo credits are our internal representation of the unit of work that
        devlo performs. For reference, here are some estimates of credits
        required to perform various tasks
        <br />
        <br />
        Simple ticket resolution: 300-500 credits
        <br />
        Simple PR Review: 200-300 credits
        <br />
        <br />
        Note: These are rough estimates for simple tasks. The actual usage of
        credits depends on the size and complexity of the task, and can be much
        more than what is displayed here.
      </p>
    ),
  },
  {
    question: "Can I purchase more credits?",
    answer: (
      <p>
        Yes! Go to&nbsp;
        <Link to="/orgs">Organizations</Link> and click on the organization you
        want to buy credits for. In the subscriptions panel, add the amount of
        credits to buy ($1 = 100 credits) and select purchase. You will be taken
        to a checkout page where you can confirm your payment details and
        checkout.
      </p>
    ),
  },
  {
    question: "How does devlo generate unit tests? How is it different?",
    answer: (
      <p>
        devlo uses advanced algorithms and AI models trained on extensive
        datasets to ensure the quality and relevance of the generated unit
        tests.
        <br />
        <br /> However, LLM generated tests do not always get it right,
        resulting in back and forth until valid tests are achieved. devlo uses a{" "}
        <span className="bold">proprietary engine</span> to build a real test
        environment which it uses to run tests to make sure they pass before
        committing to your repository. It can also identify bugs or
        inconsistencies in source code (assuming business logic is correct),
        which it can fix to get tests to pass.
      </p>
    ),
  },
  {
    question: "How secure is my code with devlo?",
    answer: (
      <p>
        <span className="bold">Security is our top priority.</span> devlo only
        accesses your code to generate unit tests and uses secure APIs to
        communicate with your repositories. Your code is processed in secure
        containers which are deleted upon completion of the task.
        <br />
        <br /> We use 3rd party LLM's including OpenAI, Gemini and Claude and
        adhere to industry-standard security practices to protect your data and
        maintain confidentiality.
      </p>
    ),
  },
  {
    question: "How can I add more repositories?",
    answer: (
      <p>
        Go to your <Link to="/repos">Repositories</Link> page and click on the
        &nbsp;
        <span className="bold">"+ Add repositories"</span> button on the top.
        This will lead you to GitHub's installation page for your organization
        where you can select more repositories.
      </p>
    ),
  },
  {
    question: "How can I remove a repository?",
    answer: (
      <p>
        Go to your <Link to="/repos">Repositories</Link> page and click on the
        repository you want to remove. This will lead you to the repository's
        page. Click on the <span className="bold">"Modify Installation"</span>
        &nbsp;button at the top. This will redirect you to GitHub's installation
        page for your organization where you can remove the repository from the
        devlo app.
      </p>
    ),
  },
  {
    question: "How can I modify seats/users?",
    answer: (
      <p>
        By default, all users in your organization will get access to devlo. You
        can also allow only certain users in to access devlo. To select this
        option and add more seats, go to&nbsp;
        <Link to="/orgs">Organizations</Link> and click on the organization to
        modify. You can add or remove users here.
        <br />
        <br />
        <span className="bold">Note:</span> If you have selected the manually
        add seats option, devlo will only respond to users within your
        organization that you have added.
      </p>
    ),
  },
  {
    question: "Which programming languages does devlo support?",
    answer: (
      <p>
        devlo currently supports a wide range of popular programming languages
        including{" "}
        <span className="bold">
          Python, JavaScript, TypeScript, Java, GO and R
        </span>
        . We are continuously working to add support for more languages based on
        user demand.
      </p>
    ),
  },
];

// Utility function to slugify question into an ID-friendly format
const slugify = (str) =>
  str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-") // Replace spaces and special chars with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading/trailing hyphens

function HowItWorks() {
  // State to keep track of which FAQ is open
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to handle accordion toggle
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  // Scroll to FAQ item if a hash exists in the URL
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const index = faqData.findIndex(
        (faq) => `#${slugify(faq.question)}` === hash
      );
      if (index !== -1) {
        setActiveIndex(index);
        const element = document.getElementById(
          slugify(faqData[index].question)
        );
        if (element) element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <>
      <Navbar />
      <div className="f-col aic content">
        <div className="fccc how-it-works">
          <h1>FAQ</h1>
          <div className="accordion">
            {faqData.map((faq, index) => {
              const questionId = slugify(faq.question); // Generate dynamic ID
              return (
                <div key={index} id={questionId} className="accordion-item">
                  <div
                    className="f-row aic accordion-title"
                    onClick={() => toggleAccordion(index)}
                  >
                    {faq.question}
                    <div className="icon">
                      <i className="material-icons">
                        {activeIndex === index ? (
                          <>keyboard_arrow_up</>
                        ) : (
                          <>keyboard_arrow_down</>
                        )}
                      </i>
                    </div>
                  </div>
                  {activeIndex === index && (
                    <div className="accordion-content">{faq.answer}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HowItWorks;
