import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useOrgContext } from "../components/utils/OrgContext";
import Loading from "../components/utils/Loading";

const Subscription = () => {
  const { orgId } = useParams();
  const { selectOrgId } = useOrgContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (orgId) {
      selectOrgId(orgId);

      navigate("/subscription");
    }
  }, [orgId, selectOrgId, navigate]);

  return <Loading />;
};

export default Subscription;
