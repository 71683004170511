import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Sidebar from "../components/Sidebar";
import "../components/Dashboard.scss";
import "./OrganizationDetail.scss";
import { fetchGitHubUser } from "../components/utils/githubApi";
import Loading from "../components/utils/Loading";
import LogoNavbar from "../components/utils/LogoNavbar";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { loadStripe } from "@stripe/stripe-js";
import ConfirmationModal from "../components/ConfirmationModel";
import {
  batchSeatsUpdate,
  createCheckoutSession,
  createPortalSession,
  updateAutoAssignSeats,
} from "../components/api/dashboardFunctions";
import { useOrgContext } from "../components/utils/OrgContext";
import FpErrorMessage from "../components/utils/FpErrorMessage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const OrganizationDetail = () => {
  const { currentOrg, loadingOrgs } = useOrgContext();
  const [seats, setSeats] = useState([]);
  const [error, setError] = useState("");
  const [seatError, setSeatError] = useState("");
  const [username, setUsername] = useState("");
  const [isSeatOptionDirty, setIsSeatOptionDirty] = useState(false);
  const [isSeatChangeDirty, setIsSeatChangeDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [initialAutoAssignSeats, setInitialAutoAssignSeats] = useState(true);
  const [newAutoAssignSeats, setNewAutoAssignSeats] = useState(true);
  const [creditAmount, setCreditAmount] = useState(10); // Default to $10 minimum
  const [isManageSubscriptionLoading, setIsManageSubscriptionLoading] =
    useState(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);

  // Local state for batching changes
  const [pendingAdditions, setPendingAdditions] = useState([]);
  // Format: [{ username: string, userDetails: object }]
  const [pendingRemovals, setPendingRemovals] = useState([]);

  // Modal state for reviewing & confirming batch changes
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (currentOrg) {
      setSeats(currentOrg.seats);
      setInitialAutoAssignSeats(currentOrg.org.autoAssignSeats);
      setNewAutoAssignSeats(currentOrg.org.autoAssignSeats);
    }
  }, [currentOrg]);

  const handleCustomCheckout = async () => {
    if (creditAmount < 10) {
      setError("Minimum purchase is $10.");
      return;
    }
    setIsPurchaseLoading(true); // Start loading spinner
    setError("");

    try {
      const { sessionId } = await createCheckoutSession(
        currentOrg.id,
        creditAmount,
        `${window.location.origin}/org/${currentOrg.id}?success=true`,
        `${window.location.origin}/org/${currentOrg.id}?canceled=true`
      );

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      setError("Failed to start checkout process");
    } finally {
      setIsPurchaseLoading(false); // Stop loading spinner
    }
  };

  const handleManageSubscription = async () => {
    setIsManageSubscriptionLoading(true); // Start loading spinner
    setError("");
    try {
      const { url } = await createPortalSession(
        currentOrg.id,
        `${window.location.origin}/org/${currentOrg.id}`
      );

      window.location.href = url;
    } catch (error) {
      setError("Failed to access billing portal");
    } finally {
      setIsManageSubscriptionLoading(false); // Start loading spinner
    }
  };

  const addPendingUser = async () => {
    setSeatError("");
    if (!username.trim()) {
      setSeatError("Username is required!");
      return;
    }

    const lowerUsername = username.trim().toLowerCase();

    const userInAdditions = pendingAdditions.some(
      (entry) => (entry.username || "").toLowerCase() === lowerUsername
    );
    const userInSeats = seats.some(
      (seat) => (seat.username || "").toLowerCase() === lowerUsername
    );

    if (userInAdditions || userInSeats) {
      setSeatError("User already exists in your pending changes or seats.");
      return;
    }

    try {
      const userDetails = await fetchGitHubUser(username);
      if (!userDetails || !userDetails.id) {
        setSeatError(
          "User not found. Please check the GitHub username and try again."
        );
        return;
      }

      setPendingAdditions([...pendingAdditions, { username, userDetails }]);
      setIsSeatChangeDirty(true);
      setUsername("");
    } catch (err) {
      setSeatError("Failed to fetch GitHub user details. Please try again.");
    }
  };

  const removePendingSeat = (seat) => {
    if (!seat.userGithubId) return; // Safety check

    if (seats.length + pendingAdditions.length - pendingRemovals.length === 1) {
      // Check if the last seat is being removed
      setSeatError(
        "You must have at least one seat assigned. Cannot remove the last seat."
      );
      return;
    }

    const seatIdStr = seat.userGithubId.toString();
    // Check if seat is in pendingAdditions (not saved yet)
    const indexInPending = pendingAdditions.findIndex(
      (entry) => entry.userDetails.id.toString() === seatIdStr
    );

    if (indexInPending !== -1) {
      // If in pendingAdditions, removing it just means removing from pendingAdditions
      const newAdditions = [...pendingAdditions];
      newAdditions.splice(indexInPending, 1);
      setPendingAdditions(newAdditions);
    } else {
      // If it exists in seats (i.e., DB), add to pendingRemovals if not already there
      if (!pendingRemovals.includes(seatIdStr)) {
        setPendingRemovals([...pendingRemovals, seatIdStr]);
        setIsSeatChangeDirty(true);
      } else {
        setSeatError("This seat is already queued for removal.");
      }
    }
  };

  const onEnterPress = (e) => {
    if (e.key === "Enter" && username.trim()) {
      addPendingUser();
    }
  };

  const saveAutoAssignSetting = async () => {
    setSaving(true);
    setSeatError("");
    setSaveMessage("");
    try {
      await updateAutoAssignSeats(currentOrg.id, newAutoAssignSeats);

      setIsSeatOptionDirty(false);
      setInitialAutoAssignSeats(newAutoAssignSeats);
      setSaveMessage("Setting updated!");
    } catch (error) {
      setSeatError(
        error.message ||
          "Failed to save the setting. Please try again or contact support."
      );
    }
    setSaving(false);
  };

  const openConfirmationModal = () => {
    // Check if there are any changes at all
    if (pendingAdditions.length === 0 && pendingRemovals.length === 0) {
      setSeatError("No changes to confirm.");
      return;
    }
    setShowConfirmationModal(true);
  };

  const confirmChanges = async () => {
    setShowConfirmationModal(false); // Close the confirmation modal
    setSeatError(""); // Clear previous errors

    try {
      // Prepare the payload for the function
      const additionsPayload = pendingAdditions.map(
        ({ userDetails }) => userDetails
      );

      // Call the Firebase function
      const { message, seats: updatedSeats } = await batchSeatsUpdate(
        currentOrg.id,
        additionsPayload,
        pendingRemovals
      );
      // Update the UI state with new seats
      setSeats(updatedSeats);

      // Clear pending changes
      setPendingAdditions([]);
      setPendingRemovals([]);

      // Show a success message
      alert(message); // Use a toast or modal if preferred
    } catch (err) {
      // Handle specific error codes from the backend
      switch (err.code) {
        case "unauthenticated":
          setSeatError("You must be logged in to make changes.");
          break;
        case "not-found":
          setSeatError("Organization not found.");
          break;
        case "permission-denied":
          setSeatError("Only the organization admin can modify seats.");
          break;
        case "invalid-argument":
          setSeatError("Invalid data provided. Please check your input.");
          break;
        case "already-exists":
          setSeatError("One or more users are already assigned seats.");
          break;
        case "resource-exhausted":
          setSeatError("You cannot exceed the maximum seat limit.");
          break;
        case "failed-precondition":
          setSeatError("At least one seat must remain assigned.");
          break;
        case "internal":
          setSeatError(
            "An unexpected error occurred. Please try again later or contact us at help@devlo.ai."
          );
          break;
        default:
          setSeatError(
            "An error occurred. Please try again later or contact us at help@devlo.ai."
          );
      }
    }
  };

  const unifiedSeats = [
    ...seats,
    ...pendingAdditions.map((addition) => ({
      userGithubId: addition.userDetails.id,
      username: addition.username,
      avatarUrl: addition.userDetails.avatar_url,
      htmlUrl: addition.userDetails.html_url,
      isPendingAddition: true,
    })),
  ];
  const statusDisplayNames = {
    active: "Active",
    trialing: "Trialing",
    past_due: "Past Due",
    canceled: "Canceled",
    incomplete: "Incomplete",
    incomplete_expired: "Incomplete (Expired)",
    unpaid: "Unpaid",
    paused: "Paused",
  };
  // Add a fallback for unknown statuses
  const getDisplayNameForStatus = (status) =>
    statusDisplayNames[status] || "Unknown";

  return (
    <div className="f-row aifs dashboard">
      <Sidebar showTab="orgs" />
      {loadingOrgs ? (
        <Loading />
      ) : currentOrg ? (
        <div className="main-outer">
          <div className="f-col aifs main org-deets">
            <LogoNavbar />
            <div className="f-row aic jcsb header">
              <div className="frcc lefty">
                <a
                  href={currentOrg.org.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="frcc title"
                >
                  <h1 className="f-row aifs">
                    {currentOrg.org.name}
                    <i className="material-icons open-icn">open_in_new</i>
                  </h1>
                </a>
                <div
                  className={`frcc status-badge ${
                    currentOrg.org.active ? "active" : "inactive"
                  }`}
                >
                  {currentOrg.org.active && (
                    <span className="pulsating-circle"></span>
                  )}
                  {currentOrg.org.active ? "Active" : "Inactive"}
                </div>
              </div>
            </div>
            {!currentOrg.org.active && (
              <div>
                <a
                  href="https://github.com/apps/devloai/installations/select_target"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="frcc cta-btn"
                  style={{ color: "#fff" }}
                >
                  <i className="material-icons">add</i> Reconnect Organization
                </a>
              </div>
            )}
            {error && (
              <p className="f-row aic error" style={{ color: "red" }}>
                ⚠️ {error}
              </p>
            )}

            {currentOrg.org.active && (
              <>
                <div className="subscription-section">
                  <h2 className="subscription-title">
                    Subscription{" "}
                    <span className="plan-tag pro">
                      {currentOrg.org.plan === "startup_monthly"
                        ? "Startup"
                        : currentOrg.org.plan === "pro_monthly"
                        ? "Pro"
                        : currentOrg.org.plan === "free"
                        ? "Free"
                        : "N/A"}
                    </span>
                  </h2>

                  <div className="f-row jcsb aifs subscription-details">
                    {/* Left Section */}
                    <div className="f-col sub-details-left">
                      <div>
                        <p>
                          <span className="bold">Status:&nbsp;</span>{" "}
                          {getDisplayNameForStatus(
                            currentOrg.org.subscriptionStatus
                          )}
                        </p>
                        {currentOrg.org.subscriptionStatus !== "paused" &&
                          currentOrg.org.subscriptionStatus !== "canceled" && (
                            <p>
                              <span className="bold">
                                {currentOrg.org.cancelAtPeriodEnd
                                  ? "Ends"
                                  : "Renews"}
                                :&nbsp;
                              </span>
                              {currentOrg.org.currentPeriodEnd
                                ? new Date(
                                    currentOrg.org.currentPeriodEnd.seconds *
                                      1000
                                  ).toLocaleDateString()
                                : "N/A"}
                            </p>
                          )}

                        <p>
                          <span className="bold">Payment setup:&nbsp;</span>
                          {!currentOrg.org.hasPaymentMethod ? (
                            <span className="no-payment">
                              No payment method detected
                            </span>
                          ) : (
                            <span className="payment-detected">Done</span>
                          )}
                        </p>
                      </div>
                      <div>
                        <button
                          className="frcc manage-subscription-btn"
                          onClick={handleManageSubscription}
                          disabled={isManageSubscriptionLoading}
                        >
                          Manage subscription
                          {isManageSubscriptionLoading && (
                            <CircularProgress
                              style={{ width: 10, height: 10, color: "white" }}
                            />
                          )}
                        </button>
                        <p className="sub-info">
                          Click to change plans, manage billing & subscription.
                          <br />
                          Note: A payment method needs to attached for upgrading
                          plans.
                        </p>
                      </div>
                    </div>

                    {currentOrg.org.subscriptionStatus !== "canceled" &&
                      currentOrg.org.subscriptionStatus !== "paused" && (
                        <>
                          {/* Divider */}
                          <div className="divider"></div>

                          {/* Right Section */}
                          <div className="f-col sub-details-right">
                            <div className="f-col base-sec">
                              <p className="bold">Monthly base credits</p>
                              <div
                                className="credit-usage-bar"
                                style={{
                                  backgroundColor: "#e0e0e0", // Default background
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                  height: "12px",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="usage-bar-fill"
                                  style={{
                                    width: `${
                                      Math.max(
                                        0,
                                        Math.min(
                                          (currentOrg.org.monthlyCreditsUsed /
                                            currentOrg.org.monthlyCredits) *
                                            100,
                                          100
                                        )
                                      ) || 0
                                    }%`,
                                    backgroundColor:
                                      currentOrg.org.monthlyCreditsUsed >=
                                      currentOrg.org.monthlyCredits
                                        ? "#d9534f" // Red when fully used or exceeded
                                        : currentOrg.org.monthlyCreditsUsed >=
                                          currentOrg.org.monthlyCredits * 0.9
                                        ? "#f0ad4e" // Orange when less than 10% left
                                        : "#5cb85c", // Green for normal usage
                                    transition: "width 0.5s ease-in-out",
                                    height: "100%",
                                  }}
                                ></div>
                              </div>
                              <p>
                                <span className="bold">Usage:</span>{" "}
                                {Math.max(
                                  currentOrg.org.monthlyCreditsUsed || 0,
                                  0
                                )}{" "}
                                / {currentOrg.org.monthlyCredits || 0}
                              </p>
                            </div>

                            <div className="f-col credits-sec">
                              <h4 className="bold">Additional credits</h4>
                              <p className="purchased-info">
                                Base credits are used up first. Purchased
                                credits never expire and roll over to the next
                                month.{" "}
                                <Link
                                  to="/faq#what-are-devlo-credits"
                                  className="credits-link"
                                >
                                  See how credits work
                                </Link>
                              </p>
                              <p className="rem-cred">
                                Remaining credits:&nbsp;
                                {currentOrg.org.purchasedCredits || 0}
                              </p>
                              <div className="f-row buy-credits">
                                <div className="input-wrapper">
                                  <span className="dollar-sign">$</span>
                                  <input
                                    type="text"
                                    value={creditAmount}
                                    placeholder="Enter amount"
                                    onChange={(e) => {
                                      const value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      ); // Allow only numbers
                                      setCreditAmount(value);
                                    }}
                                  />
                                </div>
                                <button
                                  className="frcc buy-more-btn"
                                  onClick={handleCustomCheckout}
                                  disabled={
                                    isPurchaseLoading ||
                                    !creditAmount ||
                                    Number(creditAmount) < 10
                                  }
                                >
                                  Purchase
                                  {isPurchaseLoading && (
                                    <CircularProgress
                                      style={{
                                        width: 10,
                                        height: 10,
                                        color: "white",
                                      }}
                                    />
                                  )}
                                </button>
                              </div>
                              {/* Error Message */}
                              {creditAmount && Number(creditAmount) < 10 && (
                                <p className="error-message">
                                  Minimum purchase is $10.
                                </p>
                              )}
                              <p className="purchase-info">
                                $1 = 100 credits. Minimum purchase is $10.
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>

                {currentOrg.org.subscriptionStatus !== "canceled" &&
                  currentOrg.org.subscriptionStatus !== "paused" && (
                    <div className="seats-section">
                      <h2>Seats</h2>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="seat-assignment"
                          name="seat-assignment"
                          value={newAutoAssignSeats ? "auto" : "manual"}
                          onChange={(e) => {
                            const newValue = e.target.value === "auto";
                            setNewAutoAssignSeats(newValue);
                            setIsSeatOptionDirty(
                              newValue !== initialAutoAssignSeats
                            );
                          }}
                        >
                          <FormControlLabel
                            value="auto"
                            control={<Radio color="primary" />}
                            label={
                              <div className="f-col aifs jcc">
                                <span className="description">
                                  Allow all users in the organization to
                                  interact with devlo
                                </span>
                              </div>
                            }
                          />
                          <FormControlLabel
                            value="manual"
                            control={<Radio color="primary" />}
                            label={
                              <div className="f-col aifs jcc">
                                <span className="description">
                                  Manually select users to interact with devlo.
                                </span>
                              </div>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      <div className="f-row aic save-cnt">
                        <button
                          className={`save-btn ${
                            isSeatOptionDirty ? "active" : "disabled"
                          }`}
                          disabled={!isSeatOptionDirty || saving}
                          onClick={saveAutoAssignSetting}
                        >
                          {saving ? "Saving..." : "Save"}
                        </button>
                        {saveMessage && (
                          <div className="success-message">{saveMessage}</div>
                        )}
                      </div>
                      {!initialAutoAssignSeats && !isSeatOptionDirty && (
                        <>
                          <div className="add-users">
                            <div className="f-row aic add-user">
                              <input
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter GitHub Username"
                                type="text"
                                onKeyDown={onEnterPress}
                              />
                              <button
                                onClick={addPendingUser}
                                disabled={!username.trim()}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                          {seatError && (
                            <p
                              className="f-row aic error"
                              style={{ color: "red" }}
                            >
                              ⚠️ {seatError}
                            </p>
                          )}
                          <table>
                            <thead>
                              <tr>
                                <th>Username</th>
                                <th>Modify</th>
                              </tr>
                            </thead>
                            <tbody>
                              {unifiedSeats.map((seat) => {
                                const seatIdStr = seat.userGithubId.toString();
                                const isPendingAdd =
                                  seat.isPendingAddition === true;
                                const isPendingRemove =
                                  pendingRemovals.includes(seatIdStr);

                                return (
                                  <tr key={seat.userGithubId}>
                                    <td className="f-row aic username">
                                      <a
                                        href={seat.htmlUrl}
                                        className="f-row aic username"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {seat.avatarUrl && (
                                          <img
                                            src={seat.avatarUrl}
                                            alt="user avatar"
                                            className="user-avatar"
                                          />
                                        )}
                                        <span>
                                          <span className="underline">
                                            {seat.username}
                                          </span>
                                          {isPendingAdd && (
                                            <span className="tag pending-add">
                                              Pending Add
                                            </span>
                                          )}
                                          {isPendingRemove && (
                                            <span className="tag pending-remove">
                                              Pending Remove
                                            </span>
                                          )}
                                        </span>
                                      </a>
                                    </td>
                                    <td>
                                      <div
                                        className="f-row remove-btn"
                                        tabIndex={0}
                                        onClick={() => removePendingSeat(seat)}
                                      >
                                        Remove
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div className="frcc jcsb">
                            <p className="note">
                              Seats will be updated upon confirming your
                              changes.
                            </p>
                            <button
                              className={`primary confirm-btn ${
                                isSeatChangeDirty ? "active" : "disabled"
                              }`}
                              disabled={
                                pendingAdditions.length === 0 &&
                                pendingRemovals.length === 0
                              }
                              onClick={openConfirmationModal}
                            >
                              Review Changes
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      ) : (
        <FpErrorMessage message={"No Organizations found"} />
      )}
      <ConfirmationModal
        isOpen={showConfirmationModal}
        title="Confirm Seat Changes"
        message="Review your pending additions and removals before finalizing."
        pendingAdditions={pendingAdditions}
        pendingRemovals={pendingRemovals}
        unifiedSeats={unifiedSeats} // Pass unifiedSeats to Modal
        onConfirm={confirmChanges}
        onCancel={() => setShowConfirmationModal(false)}
      />
    </div>
  );
};

export default OrganizationDetail;
